import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callFetch from 'src/utils/fetch';
import { API_URL } from 'src/utils/constant';

export const fetchAllfacilityDetails = createAsyncThunk(
  'facility/fetchAllVisitorDetails',
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    const option = {
      method: 'GET',
      // body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        authorization: localStorage.getItem('token_key'),
      },
    };
    const url = `${API_URL + 'api/v1/facility/?' + query}`;
    const response = await callFetch(url, option);
    const value = await response;
    return value;
  }
);

const facilitySlice = createSlice({
  name: 'facilityForm',
  initialState: {
    facilityForm: [],
    loading: false,
    nextPage: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllfacilityDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllfacilityDetails.fulfilled, (state, action) => {
      state.facilityForm = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchAllfacilityDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default facilitySlice.reducer;
