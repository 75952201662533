import React from 'react';
import PropTypes from 'prop-types';

//Dashboard
export const DASHBOARD = '/dashboard';

//Campaign
export const SENDBULKCAMPAGIN = '/campagin';

//HomePage
export const HOMEPAGE = '/homepage';

//401 Page
export const PAGE401 = '/accessdenied';
export const CRMDASHBOARD = '/crmdashboard';

//Campaign master
export const CAMPAIGNMASTERLIST = '/campaign';
export const CAMPAIGNMASTERCREATE = '/campaign/add';
export const CAMPAIGNMASTEREDIT = '/campaign/edit';

//SpareParts master
export const SPAREPARTSMASTERLIST = '/spareparts';
export const SPAREPARTSMASTERCREATE = '/spareparts/add';
export const SPAREPARTSMASTEREDIT = '/spareparts/edit';

//Procurement master
export const PROCUREMENTMASTERLIST = '/material';
export const PROCUREMENTMASTERCREATE = '/material/add';
export const PROCUREMENTMASTEREDIT = '/material/edit';

//HRMS
export const EMPLOYEELIST = '/employee';
export const EMPLOYEEADD = '/employee/add';
export const EMPLOYEEEDIT = '/employee/edit';
export const EMPLOYEESUBMASTER = '/hrms_submaster';

//Resource Category
export const RESOURCECATEGORYLIST = '/resource_category';
export const RESOURCECATEGORYADD = '/resource_category/add';
export const RESOURCECATEGORYEDIT = '/resource_category/edit';

//Asset Category
export const ASSETCATEGORYLIST = '/asset_category';
export const ASSETCATEGORYADD = '/asset_category/add';
export const ASSETCATEGORYEDIT = '/asset_category/edit';

//setup
export const SETUP = '/setup';
export const SETUPLISTADD = '/setup/add';
export const SETUPLISTEDIT = '/setup/edit';

//Audit
export const AUDITTRAIL = '/audittrail';

//Contacts
export const CONTACTS = '/contacts';
export const CONTACTLISTADD = '/contacts/add';
export const CREATETEMPLATE =
  '/contacts/templates/email_template_list/create_template';
export const EDITTEMPLATE =
  '/contacts/templates/email_template_list/edit_template';
// export const OPENTEMPLATE = '/contacts/openCreateTemplate';
export const EMAILTEMPLATELIST = '/contacts/templates/email_template_list';
export const CONTACTLISTEDIT = '/contacts/edit';
export const SMSCAMPAIGN =
  '/contacts/templates/email_template_list/smscampaign';
export const EMAILCAMPAIGN =
  '/contacts/templates/email_template_list/send_email';
export const CONTACTSREPORT = '/contactsReport';
export const CONTACTACTIVITYREPORT = '/contact_activity_report';
export const CONTACTPREFERENCEREPORT = '/contact_preference_report';
export const PROPERTYTOURREPORT = '/contact_property_tour_report';
export const LEADSLISTINGREPORT = '/leadsReport';

//Email and SMS Campaign in Contacts
// export const CREATETEMPLATE = '/contacts/createtemplate';
// export const OPENTEMPLATE = '/contacts/openCreateTemplate';
// export const SMSCAMPAIGN = '/contacts/smscampaign';
// export const EMAILCAMPAIGN = '/contacts/emailcampaign';

//leads
export const LEADS = '/leads';
export const ADDLEADS = '/leads/add';
export const EDITLEADS = '/leads/edit';
//lease Agreement
export const LEASE = '/lease_agreement';
export const ADDLEASE = '/lease_agreement/add';
export const EDITLEASE = '/lease_agreement/edit';
export const REVIEWLEASE = '/lease_agreement/review';
export const ADDLEASEMOVEINANDOUT = '/lease/Moveinandout/add';
export const EDITLEASEMOVEINANDOUT = '/lease/Moveinandout/edit';
export const ADDLEASEAPPROVAL = '/lease/Approval/add';
export const EDITLEASEAPPROVAL = '/lease/Approval/edit';

//lease offer
export const LEASE_OFFER = '/lease_offer';
export const ADDLEASE_OFFER = '/lease_offer/add';
export const EDITLEASE_OFFER = '/lease_offer/edit';
export const REVIEWLEASE_OFFER = '/lease_offer/review';

//user Master
export const USERMASTERLIST = '/user';
export const USERMASTERADD = '/user/add';
export const USERMASTEREDIT = '/user/edit';

// Approval Work Flow
export const APPROVALREQUEST = '/approval_requests';
export const APPROVALWORKFLOWLIST = '/approval_flow';
export const ADDAPPROVALWORKFLOW = '/approval_flow/add';
export const EDITAPPROVALWORKFLOW = '/approval_flow/edit';

//Workflow
// export const WORKFLOW ='/workflow/add'
// export const NEWWORKFLOW = '/workflow/new'
// export const WORKFLOWNAME = '/workflow'
// export const WORKFLOWCARD = '/workflow/worklow'

//Workflow
export const WORKFLOW = '';
export const NEWWORKFLOW = '';
export const WORKFLOWNAME = '';
export const WORKFLOWCARD = '';

// export const SALES = '/sales';

//RequestManagement
export const REQUESTMANAGEMENT = '/request_management';
export const REQUESTADD = '/request_management/add';
export const REQUESTEDIT = '/request_management/edit';
export const EXECUTIVEMANAGEMENT = '/executive_management';
export const EXECUTIVEEDIT = '/executive_management/edit';

//property
export const PROPERTY = '/property';
export const PROPERTYADD = '/property/add';
export const PROPERTYEDIT = '/property/edit';

//Finance
export const FINANCE = '/finance';

//CheckList
export const CHECKLISTTASK = '/checklist_task_list/edit';
export const CHECKLISTTASKLIST = '/checklist_task_list';

//ContractorAsset
export const CONTRACTORASSET = '/contractorAsset';

//Facility
export const FACILITY = '/facility';
export const LISTJOB = '/facility/listJob';
export const FACILITYBOOKLIST = '/facility_booking_list';
export const FACILITYBOOK = '/facility_booking_list/add';
export const FACILITYCLUBBOOK = '/facilityClubBook';

//CleaningJob
export const CLEANINGJOBLIST = '/cleaning_job_list';
export const EDITCLEANINGJOB = '/cleaning_job_list/edit';

//Facility Management - Gatepass
export const ADDGATEPASS = '/gate_pass_list/add';
export const EDITGATEPASS = '/gate_pass_list/edit';
export const GATEPASSLIST = '/gate_pass_list';

//Incident
export const INCIDENTFORMADD = '/incident_report_list/add';
export const INCIDENTFORMEDIT = '/incident_report_list/edit';
export const INCIDENTLIST = '/incident_report_list';

//JobPlanner
export const JOBPLANNER = '/job_planner/add';
export const JOBPLANNEREDIT = '/job_planner/edit';
export const JOBPLANNERLIST = '/job_planner';

//Feedback
export const FEEDBACKFORM = '/feedbackForm';

//Visitor
export const VISITORFORM = '/visitor_entry_list/add';
export const VISITORLIST = '/visitor_entry_list';

//EnergyManagement
export const ENERGYMANAGEMENT = '/energy_management/add';
export const ENERGYMANAGEMENTEDIT = '/energy_management/edit';
export const ENERGYMANAGEMENTLISTING = '/energy_management';
export const ENERGYMANAGEMENTSUBMASTER = '/energy_management_submaster';

//AssetInspection
export const ASSETINSPECTIONFORM = '/assetinspectionform';

//Checklist Asset item Submaster
export const CHECKLISTASSETITEM = '/checklist_asset_item';
export const CHECKLISTASSETITEMADD = '/checklist_asset_item/add';
export const CHECKLISTASSETITEMEDIT = '/checklist_asset_item/edit';

//Checklist Item Submaster
export const CHECKLISTITEM = '/checklist_item';
export const CHECKLISTITEMADD = '/checklist_item/add';
export const CHECKLISTITEMEDIT = '/checklist_item/edit';

//DetailedFinding
export const DETAILEDFINDING = '/detailedfinding';

//Settings
export const SETTINGS = '/settings';

//Widgets
export const WIDGETS = '/widgets';

//user
// export const USER = '/user';
// export const USERADD = '/user/add';
// export const USEREDIT = '/user/edit';
export const PROFILE = '/Profile';

//submasters
export const MASTER = '/workorder_submaster';
export const CONTACTMASTER = '/contact_submaster';
export const PROPERTYMASTER = '/property_submaster';
export const COMMUNITYMASTER = '/community_submaster';
export const LEADSMASTERSUB = '/leads_submaster';
export const SPAREPARTSMASTERSUB = '/spareparts_submaster';
export const PROCUREMENTMASTERSUB = '/procurement_submaster';
export const UNITSUBMASTER = '/unit_submaster';
export const ASSETSUBMASTER = '/asset_submaster';
export const LEASESUBMASTER = '/lease_submaster';
export const SALESSUBMASTER = '/sales_submaster';
export const USERSUBMASTER = '/user_submaster';
export const CUSTOMERCARE = '/customercare_submaster';
export const COMPLIANTSMASTERS = '/complaint_submasters';
export const SUPERVISORSLIST = '/supervisor_submaster';
export const TECHNICIANLIST = '/technician_submaster';

//Community
export const COMMUNITYTABLE = '/community';
export const COMMUNITYADD = '/community/add';
export const COMMUNITYEDIT = '/community/edit';

//Unit
export const UNITMASTER = '/unit';
export const UNITMASTERADD = '/unit/add';
export const UNITMASTEREDIT = '/unit/edit';

//roles
export const ROLECREATE = '/role_list/create_role';
export const ROLEEDIT = '/role_list/edit_role';
export const ROLELIST = '/role_list';

//Complaints
export const COMPLAINTS = '/complaints';
export const FRONTDESKCOMPLAINTSTABLE = '/complaints';

//ComplaintsDetails
export const MAINCOMPLAINTS = '/complaintsDetails';
export const PRINTFORM = '/complaintsDetails/print';
export const WORKORDERFORM = '/complaintsDetails/add';
export const EDITWORKORDERFORM = '/complaintsDetails/edit';

//ComplaintsDetails Technician
export const TECHNICIANFORMS = '/complaintsDetails/technician';
export const TECHNICIANTASKTABLE = '/complaintsDetails/technician';
export const TECHNICIANREMARKFORM = '/complaintsDetails/technician/add';

//ComplaintsDetails Supervisor
export const SUPERVISORTABLE = '/complaintsDetails/supervisor';
export const ASSIGNTASK = '/complaintsDetails/supervisor/assigntask';
export const EDITTASKFORM = '/complaintsDetails/supervisor/edit';

//Supervisor
export const SUPERVISOR = '/supervisor';
export const REASSIGNTASK = '/supervisor/reassigntask';
export const ASSIGNSUPERTASK = '/supervisor/assigntask';

//WorkOrder Report
export const WORKORDERREPORTS = '/workorderReports';

//Facility Management - Quotation
export const QUOTATIONLIST = '/quotation_list';
export const ADDQUOTATIONFORM = '/quotation_list/add';
export const EDITQUOTATIONFORM = '/quotation_list/edit';

//Job Planner submaster
export const JOBPLANERSUBMASTER = '/jobplanersubmaster';

//Calendar
export const CALENDARLIST = '/task_calendar';
export const LEADSCALENDARLIST = '/crm_calendar';
export const CUSTOMERCARECALENDARLIST = '/customer_helpdesk_calendar';

//Salesperson
export const SALESPERSONSLIST = '/salespersonsList';
export const SALESPERSONSTABLE = '/salesperson';
export const ADDSALESPERSON = '/salesperson/add';
export const EDITSALESPERSON = '/salesperson/edit';

//Paymentplan
export const PAYMENTPLAN = '/paymentplan';
export const ADDPAYMENTPLAN = '/paymentplan/add';
export const EDITPAYMENTPLAN = '/paymentplan/edit';

//Developers
export const DEVELOPERLIST = '/developer';
export const ADDDEVELOPER = '/developer/add';
export const EDITDEVELOPER = '/developer/edit';

//Suppliers
export const SUPPLIERTABLE = '/supplier';
export const ADDSUPPLIER = '/supplier/add';
export const EDITSUPPLIER = '/supplier/edit';
export const SUPPLIERSUBMASTER = '/supplier_submaster';

//Asset
export const ASSETMASTER = '/assets';
export const ASSETADD = '/assets/add';
export const ASSETEDIT = '/assets/edit';

//Sub-Asset
export const SUBASSETMASTER = '/sub-assets';
export const SUBASSETADD = '/sub-assets/add';
export const SUBASSETEDIT = '/sub-assets/edit';

//Sales Offer
export const SALESOFFERLISTING = '/sales/sales_offers';
export const ADDSALESOFFER = '/sales/sales_offers/add';
export const EDITSALESOFFER = '/sales/sales_offers/edit';
export const REVIEWSALESOFFER = '/sales/sales_offers/review';

//Deal Cancellation
export const DEALCANCELLATIONFORMADD = '/sales/deal_cancellation/add';
export const DEALCANCELLATIONFORMEDIT = '/sales/deal_cancellation/edit';
export const DEALCANCELLATIONLIST = '/sales/deal_cancellation';

//Sales Contract Agreement
export const SALESCONTRACTAGREEMENTFORM = '/sales/sales_contract_agreement/add';
export const EDITSALESCONTRACTAGREEMENTFORM =
  '/sales/sales_contract_agreement/edit';
export const REVIEWSALESCONTRACTAGREEMENTFORM =
  '/sales/sales_contract_agreement/review';
export const SALESCONTRACTAGREEMENTLIST = '/sales/sales_contract_agreement';
export const SALESOFFERCREATETEMPLATES =
  '/sales/sales_contract_agreement/template/create_template';
export const SALESOFFERTEMPLATES = '/sales/sales_contract_agreement/template';

//Sales Reports
export const SALESREPORT = '/sales/sales_report';

//Sales Dashboard
export const SALESDASHBOARD = '/sales/dashboard';

//VAT master
export const VATREGION = '/vat_region';
export const VATMASTER = '/vat_master';
export const VATMASTERADD = '/vat_master/add';
export const VATMASTEREDIT = '/vat_master/edit';

//Invoicing
export const INVOICELIST = '/invoice';
export const INVOICEADD = '/invoice/add';
export const INVOICEEDIT = '/invoice/edit';

//Receipts
export const RECEIPTLIST = '/receipt';
export const RECEIPTADD = '/receipt/add';
export const RECEIPTEDIT = '/receipt/edit';

//Payments
//Receipts
export const PAYMENTLIST = '/payment';
export const PAYMENTADD = '/payment/add';
export const PAYMENTEDIT = '/payment/edit';

export const LOGIN = '/login';

//ENV Path
export const API_URL = process.env.REACT_APP_API_PATH;
export const IMAGE_URL = process.env.REACT_APP_IMG_PATH;
export const SHOCKET_URL = process.env.REACT_APP_API_PATH;
// export const API_URL = process.env.REACT_APP_API_PATH;
// export const IMAGE_URL = process.env.REACT_APP_IMG_PATH;

export function parseJSON(response) {
  return response.json() || response;
}
export function parser(response) {
  return JSON.parse(response);
}
export function stringifier(response) {
  return JSON.stringify(response);
}
export function isEmpty(obj) {
  return Object.keys(obj).length ? 0 : 1;
}

/**
 * Check the response status and return
 * response or throw error
 * @param response
 * @returns {*} response or throw error
 */
export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    return response;
  }
  // if api sends back failure status code,
  // throws response and treated as error in the catch block
  throw response;
}

export function regexValid(reg, txt) {
  return !reg.test(txt);
}

//Create the star ratings
export function StarRating({ rating }) {
  const roundedRating = Math.min(Math.max(Math.floor(rating), 0), 5);
  const stars = '★'.repeat(roundedRating);
  return <span>{stars}</span>;
}

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};
